
import { Prop, Vue, Component, Watch, Model } from 'vue-property-decorator'
import querybeanApi from '@/api/project/apimanager/query-bean-config/query-bean-config-api'
import { getterCurControllerId } from '@/store'

// 选择组件
@Component({ name: 'QuerybeanSelector', components: {}, mixins: [] })
export default class RequestMethodSelector extends Vue {
  // 最新的vue版本一定要指定value 与 input
  @Model('input')
  readonly value?: number|string

  @Watch('value', { immediate: true })
  onvaluechange (newValue: number) {
    this.curValue = newValue
  }

  curValue = -1

  @Prop({
    type: String,
    default: '请选择QueryBean'
  })
  placeholder!: string

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  loading = false

  datas: Array<any> = []

  created () {
    this.loadDataFromServer()
  }

  /*   onChange (newValue: number) {
    this.$emit('input', newValue)
    this.curValue = newValue
  } */

  getRawData (uuid: string) {
    const index = this.datas.map(e => e.uuid).indexOf(uuid)
    return ~index ? this.datas[index] : undefined
  }

  onSelect (newValue: any) {
    this.$emit('input', newValue.value)
    this.curValue = newValue.value
    this.$emit('on-select', this.getRawData(newValue.value))
  }

  initData () {
    this.$emit('input', undefined)
    this.curValue = -1
  }

  // 加载下拉框选项
  loadDataFromServer () {
    this.loading = true
    querybeanApi.query({
      page: 1,
      pageSize: 1000,
      sorts: [],
      controllerConfigId: getterCurControllerId()
    }).then(res => {
      this.datas = res.data?.items || []
    }).catch((err) => {
      this.$Notice.error({
        title: '获取枚举失败',
        desc: err
      })
    }).finally(() => {
      this.loading = false
    })
  }
}
