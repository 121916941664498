/**
 * Api参数配置模块
 */

import DefaultApiService from '@/libs/api-request'
import request, { ApiResult, DataApiResult, PageDataApiResult, PagerQueryBean } from '@/libs/http-request'
import { ApiParamConfig } from './api-param-config'

/**
 * Api参数配置服务请求api接口
 */
class ApiParamConfigApi extends DefaultApiService<ApiParamConfig> {
}

export default new ApiParamConfigApi('/api/apiParamConfig')
