
import { BaseModal } from '@/libs/modal-helper'
import { Prop, Vue, Component } from 'vue-property-decorator'
import ApiConfigBaseModal from './api-config-base-modal.vue'
import { Form } from 'view-design'
import { ControllerConfigDetail } from '@/api/project/apimanager/api-config/controller-config-detail'
import EntitySelector, {
  TreeNode
} from '@/components/selector/entity-selector.vue'
import _ from 'lodash'
@Component({
  name: 'ApiReturnTypeUpdateModal',
  components: { ApiConfigBaseModal, EntitySelector }
})
export default class ApiReturnTypeUpdateModal extends Vue implements BaseModal {
  @Prop({
    type: String,
    default: '返回值类型'
  })
  title!: string

  @Prop({
    type: Object,
    default: () => {
      return {}
    }
  })
  dataProps!: ControllerConfigDetail

  data: ControllerConfigDetail = _.cloneDeep(this.dataProps)

  /// 表单校验规则
  rules = {
    /* apiMethodReturnType: [
      {
        required: true,
        message: 'API返回类型不能为空',
        trigger: 'blur'
      }
    ],
    fromEntityUuid: [
      {
        required: true,
        message: 'API返回类型关联实体不能为空',
        trigger: 'blur'
      }
    ] */
  }

  show (): void {
    ;(this.$refs.dlg as ApiConfigBaseModal).show()
  }

  close (): void {
    ;(this.$refs.dlg as ApiConfigBaseModal).close()
  }

  doSave (): void {
    const form = this.$refs.form as Form

    form.validate(result => {
      if (result) {
        this.$emit('onOk', this.data)
      } else {
        this.$Notice.error({
          title: '错误',
          desc: '数据检查失败!'
        })
      }
    })
  }

  doCancel (): void {
    this.$emit('onCancel')
  }

  onSelect (entity: TreeNode): void {
    this.data.apiMethodReturnFullClassName = entity.label
  }
}
