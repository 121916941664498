
import { BaseModal } from '@/libs/modal-helper'
import { Form } from 'view-design'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

/**
 * api参数自动范围限定
 */
@Component({
  name: 'AutoLimitEditModal'
})
export default class RightEditModal extends Vue implements BaseModal {
  showModal = false
  formData: {rights: string[]} = { rights: [] }

  @Ref()
  readonly form!: Form

  @Prop({
    type: String,
    default: '',
    required: false
  })
  readonly limits!: string

  readonly rules = { message: '范围限定值不能为空', trigger: 'blur', required: true }

  show (): void {
    this.showModal = true
  }

  close (): void {
    this.showModal = false
  }

  doSave (): void {
    this.form.validate(result => {
      if (result) {
        this.$emit('onOk', this.formData.rights.map(e => e.trim()).filter(item => item.length > 0).join('/'))
      } else {
        this.$Notice.error({
          title: '错误',
          desc: '数据检查失败!'
        })
      }
    })
  }

  doCancel (): void {
    this.$emit('onCancel')
  }

  created () {
    this.formData.rights = this.limits?.split('/') || []
    if (this.formData.rights.length === 0) {
      this.formData.rights = ['']
    }
  }

  onLimitRemove (index: number) {
    this.formData.rights.splice(index, 1)
  }
}
