
import { Prop, Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { ControllerConfigDetail } from '@/api/project/apimanager/api-config/controller-config-detail'
import ApiConfigApi from '@/api/project/apimanager/api-config/controller-config-detail-api'
import ApiParamList from './api-param-list/api-param-list.vue'
import Modals from './modal/modals'
import RequestMethodSelector from '@/components/selector/request-method-selector.vue'
import EntitySelector from '@/components/selector/entity-selector.vue'
import FieldSelector from '@/components/selector/field-selector.vue'
import { Form, Select } from 'view-design'
import controllerConfigApi from '@/api/project/apimanager/controller-config/controller-config-api'
import EntityModelManger from '@/libs/entitymodel'
import { getterEntityModelVersion, getterProjectId } from '@/store'
import Entity from '@/libs/entitymodel/entity'
import EntityField from '@/libs/entitymodel/entity-field'
import EntityLeftJoin from '@/libs/entitymodel/entity-leftjoin'
import { getShortUuid } from '@/libs/uuid'

interface LimitInject {
  className?: string;
  entityUuid?: string;
  alias?: string;
  fieldUuid?: string;
}

export interface DecodedLimitInjectControllerConfigDetail
  extends ControllerConfigDetail {
  limitInjectList: LimitInject[];
  limitInjectEntityFields: EntityField[][];
}

@Component({
  name: 'ApiEditor',
  components: {
    ApiParamList,
    RequestMethodSelector,
    EntitySelector,
    FieldSelector
  }
})
export default class ApiEditor extends Vue {
  @Prop({
    type: Number,
    default: undefined
  })
  id?: number

  fromEntityUuid: string | null = null

  @Watch('id')
  onIdChange () {
    this.loadDataFromServer()
  }

  @Ref()
  readonly form!: Form

  @Ref()
  readonly limitInjectEntitySelect!: Select

  @Ref()
  readonly limitInjectFieldSelect!: Select

  isShowSpin = false

  apiConfig: DecodedLimitInjectControllerConfigDetail = {
    limitInjectList: [],
    limitInjectEntityFields: []
  }

  manager: EntityModelManger = new EntityModelManger()
  /** 控制器关联的实体 */
  fromEntity: Entity | null = null

  /** 存在范围注入 */
  limitInject = false

  get readonly () {
    // return !this.apiConfig.canDelete
    return false
  }

  get leftTarget (): EntityLeftJoin[] {
    if (!this.fromEntity) {
      return []
    }
    const _joins = this.fromEntity?.joins || []
    if (_joins.findIndex(e => e.joinEntityUuid === this.fromEntity?.uuid) === -1) {
      _joins.unshift({
        joinEntityUuid: this.fromEntity?.uuid,
        uuid: getShortUuid(),
        joinAlias: ''
      } as any)
    }
    return _joins
  }

  onLimitInjectEntityChange (item?: Record<string, any>, index?: number) {
    if (index !== undefined && this.apiConfig?.limitInjectList?.[index]) {
      this.$set(this.apiConfig.limitInjectList[index], 'fieldUuid', undefined)
      this.$set(this.apiConfig.limitInjectEntityFields, index, [])
      this.$set(this.apiConfig.limitInjectList[index], 'alias', '')
    }
    if (index !== undefined && item?.value) {
      this.$set(
        this.apiConfig.limitInjectEntityFields,
        index,
        this.manager.getEntityAllFieldes(item.value).filter(f => !f.transient)
      )
      this.$set(this.apiConfig.limitInjectList[index], 'alias', item?.tag)
    }
  }

  @Watch('limitInject')
  onLimitInjectChange (newValue: boolean) {
    if (newValue === false) {
      this.apiConfig.limitInjectList = []
    } else if (
      newValue === true &&
      this.apiConfig.limitInjectList?.length < 1
    ) {
      this.apiConfig.limitInjectList?.push({})
    }
  }

  onAddLimitInject () {
    this.apiConfig.limitInjectList
      ? this.apiConfig.limitInjectList.push({})
      : (this.apiConfig.limitInjectList = [{}])
  }

  getLimitFields (content?: string): EntityField[][] {
    if (!content) return []
    const entityList = this.decodeLimitInject(content)
    return entityList.map(entity => {
      return entity.entityUuid
        ? this.manager
          .getEntityAllFieldes(entity.entityUuid)
          .filter(f => !f.transient)
        : []
    })
  }

  readonly rules = {
    limitInjectClassName: [
      { required: true, message: '请输入注入类名', trigger: 'blur' }
      /* {
        required: true,
        pattern: /^([A-Z]{1}[0-9a-zA-Z]+)*$/,
        message: '请使用大写开头的驼峰格式',
        trigger: 'blur'
      } */
    ],
    limitInjectEntityUuid: [
      { required: true, message: '请选择注入实体', trigger: 'blur' }
    ],
    limitInjectFieldUuid: [
      { required: true, message: '请选择注入字段', trigger: 'blur' }
    ]
  }

  created () {
    const projectId = getterProjectId()
    const versionUuid = getterEntityModelVersion()?.uuid
    if (projectId && versionUuid) {
      this.manager.loadAllOrgsAndEntities(projectId, versionUuid).then(() => {
        this.loadDataFromServer()
      })
    }
  }

  loadDataFromServer () {
    if (!this.id) {
      return
    }
    this.isShowSpin = true
    ApiConfigApi.getItemById(this.id)
      .then(resp => {
        this.apiConfig = {
          ...resp.data!,
          limitInjectList: this.decodeLimitInject(resp.data?.limitInject),
          limitInjectEntityFields: this.getLimitFields(resp.data?.limitInject)
        }
        this.limitInject = !!this.apiConfig.limitInject
        return controllerConfigApi.getItemById(
          this.apiConfig.controllerConfigId!
        )
      })
      .then(res => {
        this.fromEntityUuid = res.data?.fromEntityUuid || null
        if (this.fromEntityUuid) {
          this.fromEntity =
            this.manager.getEntityByUuid(this.fromEntityUuid) || null
        }
      })
      .catch(err => {
        this.$Message.error('查询失败，原因：' + err.message)
      })
      .finally(() => {
        this.isShowSpin = false
      })
  }

  /**
   * 解码范围注入字符串
   * @param limitInjectStr 范围注入字符串
   */
  decodeLimitInject (content?: string): LimitInject[] {
    if (!content) return []
    return content.split('/').map(e => {
      const _decode = e.split(',')
      return {
        className: _decode?.[0],
        entityUuid: _decode?.[1],
        alias: _decode?.[2],
        fieldUuid: _decode?.[3]
      }
    })
  }

  /**
   * 编码范围注入字符串
   * @param limitInjectList 范围注入列表
   */
  encodeLimitInject (content?: LimitInject[]): string | undefined {
    if (content === undefined || content.length === 0) return undefined
    return content
      .map(e => {
        return `${e.className},${e.entityUuid},${e.alias || ''},${e.fieldUuid}`
      })
      .join('/')
  }

  onDeleteLimitInject (index: number) {
    this.apiConfig.limitInjectList.splice(index, 1)
  }

  onCancel () {
    this.$router.push({
      name: 'controller-view',
      params: { id: this.apiConfig.controllerConfigId + '' }
    })
  }

  onClickUpdateApiConfigButton () {
    const that = this
    Modals.showApiReturnTypeUpdateModal(this.apiConfig, data => {
      return new Promise<void>((resolve, reject) => {
        ApiConfigApi.updateItem(data)
          .then(res => {
            this.$Message.success('操作成功')
            this.loadDataFromServer()
            resolve()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      })
    })
  }

  onSavePage () {
    this.form.validate(valid => {
      if (valid) {
        this.apiConfig.limitInject = this.encodeLimitInject(
          this.apiConfig.limitInjectList
        )
        return ApiConfigApi.updateItem(this.apiConfig)
          .then(() => {
            this.$Message.success('操作成功')
            this.loadDataFromServer()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      } else {
        this.$Message.error('表单校验失败')
      }
    })
  }

  /// 实体标题
  getEntityTitle (uuid: string) {
    const joins =
      this.fromEntity?.joins.filter(item => item.uuid === uuid) || []
    if (joins.length === 0) return `没有找到${uuid}联结对象`
    const join = joins[0]
    const entity = this.manager.entitiesMap[join.joinEntityUuid]
    const alias = join.joinAlias

    return `${entity.title}(${entity.name})(alias:${alias})`
  }
}
