
import { BaseModal } from '@/libs/modal-helper'
import { Prop, Vue, Component } from 'vue-property-decorator'
import ApiParamBaseModal from './api-param-base-modal.vue'
import { Form } from 'view-design'
import { ApiParamConfig } from '@/api/project/apimanager/api-param-config/api-param-config'
import ApiParamTypeSelector from '@/components/selector/api-param-type-selector.vue'
import QuerybeanSelector from '@/components/selector/querybean-selector.vue'

import _ from 'lodash'
import { QueryBeanConfig } from '@/api/project/apimanager/query-bean-config/query-bean-config'

@Component({
  name: 'ApiParamCreatorModal',
  components: { ApiParamBaseModal, ApiParamTypeSelector, QuerybeanSelector }
})
export default class ApiParamCreatorModal extends Vue implements BaseModal {
  @Prop({
    type: String,
    default: '添加Api参数'
  })
  title!: string

  @Prop({
    type: Object,
    default: () => {
      return {}
    }
  })
  dataProps!: ApiParamConfig

  data: ApiParamConfig = _.cloneDeep(this.dataProps)

  /// 表单校验规则
  rules = {
    title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
    paramName: [
      { required: true, message: '参数名称不能为空', trigger: 'blur' }
    ],
    dataTypeClassName: [
      { required: true, message: '数据类型不能为空', trigger: 'blur' }
    ],
    fromQuerybeanUuid: [
      {
        required: true,
        message: 'QueryBean不能为空',
        trigger: 'blur'
      }
    ]
  }

  show (): void {
    ;(this.$refs.dlg as ApiParamBaseModal).show()
  }

  close (): void {
    ;(this.$refs.dlg as ApiParamBaseModal).close()
  }

  doSave (): void {
    const form = this.$refs.form as Form

    form.validate(result => {
      if (result) {
        this.$emit('onOk', this.data)
      } else {
        this.$Notice.error({
          title: '错误',
          desc: '数据检查失败!'
        })
      }
    })
  }

  doCancel (): void {
    this.$emit('onCancel')
  }

  onSelect (item: QueryBeanConfig) {
    (this.data as any).dataTypeClassName = item.className
  }
}
