
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { ApiParamConfig } from '@/api/project/apimanager/api-param-config/api-param-config'
import ApiParamConfigApi from '@/api/project/apimanager/api-param-config/api-param-config-api'
import { PageListData, PagerQueryBean } from '@/libs/http-request'
import _ from 'lodash'
import Modals from './modal/modals'

const defaultQuery = {
  page: 1,
  pageSize: 100,
  sorts: ['-createTime']
}

@Component({
  name: 'ApiParamList',
  components: {}
})
export default class ApiParamList extends Vue {
  @Prop({
    type: Number,
    default: undefined
  })
  apiConfigId?: number;

  @Prop({
    type: Boolean,
    default: false,
    required: false
  })
  readonly readonly!: boolean

  @Watch('apiConfigId')
  onIdChange () {
    this.loadDataFromServer()
  }

  isShowSpin = false;

  apiParamList: Array<ApiParamConfig> = [];

  /// 查询数据
  queryData: PagerQueryBean = _.cloneDeep(defaultQuery);

  pageData: PageListData<ApiParamConfig> = {
    items: [],
    total: 0,
    pageSize: 10000
  };

  created () {
    this.loadDataFromServer()
  }

  loadDataFromServer () {
    if (!this.apiConfigId) {
      return
    }
    this.queryData.apiConfigId = this.apiConfigId
    this.isShowSpin = true
    ApiParamConfigApi.query(this.queryData)
      .then(resp => {
        this.pageData = resp.data!
        this.apiParamList = this.pageData.items
      })
      .catch(err => {
        this.$Message.error('查询失败，原因：' + err.message)
      })
      .finally(() => {
        this.isShowSpin = false
      })
  }

  onClickAddNewApiParamButton () {
    const that = this
    if (!that.apiConfigId) {
      return
    }
    Modals.showApiParamConfigCreatorModal(this.apiConfigId!, data => {
      return new Promise<void>((resolve, reject) => {
        data.apiConfigId = that.apiConfigId
        ApiParamConfigApi.insertItem(data)
          .then(res => {
            this.$Message.success('操作成功')
            this.loadDataFromServer()
            resolve()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      })
    })
  }

  onClickUpdateApiParamButton (apiParamConfig: ApiParamConfig) {
    const that = this
    Modals.showApiParamConfigUpdaterModal(apiParamConfig, data => {
      return new Promise<void>((resolve, reject) => {
        ApiParamConfigApi.updateItem(data)
          .then(res => {
            this.$Message.success('操作成功')
            this.loadDataFromServer()
            resolve()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      })
    })
  }

  onClickDeleteApiParamButton (id: number) {
    this.$Modal.confirm({
      title: '是否删除？',
      content: '是否删除该参数？',
      onOk: () => {
        ApiParamConfigApi.deleteItemByIds([id])
          .then(resp => {
            this.$Message.info('操作成功')
            this.loadDataFromServer()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      }
    })
  }

  onAutoLimitClick (item: ApiParamConfig) {
    Modals.showAutoLimitEditModal(item.autoLimits || '', async (data) => {
      try {
        item.autoLimits = data
        await ApiParamConfigApi.updateItem(item)
        this.$Notice.success({ title: '操作成功' })
        this.loadDataFromServer()
      } catch (err) {
        this.$Notice.error({
          title: '操作失败',
          desc: err as any
        })
      }
    })
  }

  limitCount (item: ApiParamConfig) {
    return item.autoLimits?.split('/')?.filter(x => x.length > 0).length || 0
  }
}
