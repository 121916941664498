
import EntityModelManger from '@/libs/entitymodel'
import EntityField from '@/libs/entitymodel/entity-field'
import { getterEntityModelVersion, getterProject } from '@/store'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

/**
 * 字段选择器
 */
@Component({
  name: 'FieldSelector'
})
export default class extends Vue {
  /** 目标实体uuid */
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  readonly entityUuid?: string

  /** 是否显示transient字段 */
  @Prop({
    type: Boolean,
    default: false,
    required: false
  })
  readonly transient!: boolean

  /** 实体管理器 */
  manager: EntityModelManger = new EntityModelManger()
  /** 字段列表 */
  fields: EntityField[] = []
  /** 实体加载完成 */
  entityLoaded = false

  @Watch('entityUuid')
  onEntityChange () {
    if (this.entityUuid && this.entityUuid !== '' && this.entityLoaded) {
      this.fields = this.manager.getEntityAllFieldes(this.entityUuid)
      if (!this.transient) {
        this.fields = this.fields.filter(f => !f.transient)
      }
    }
  }

  mounted () {
    setTimeout(() => {
      this.onEntityChange()
    }, 1000)
  }

  created () {
    const projectId = getterProject()?.id
    const version = getterEntityModelVersion()?.uuid
    if (projectId && version) {
      this.manager.loadAllOrgsAndEntities(projectId, version).then(() => {
        this.entityLoaded = true
      })
    }
  }
}
