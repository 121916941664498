import { ControllerConfigDetail } from '@/api/project/apimanager/api-config/controller-config-detail'
import { showModal } from '@/libs/modal-helper'
import ApiReturnTypeUpdateModal from './api-return-type-update-modal.vue'

export default class Modals {
  static showApiReturnTypeUpdateModal (config: ControllerConfigDetail, onOk?: (data: ControllerConfigDetail) => Promise<void>) {
    showModal<any>(ApiReturnTypeUpdateModal, {
      props: {
        title: '编辑Api方法返回值类型',
        dataProps: config
      }
    }, true, onOk, undefined)
  }
}
