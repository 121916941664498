
import { Prop, Vue, Component, Watch, Model } from 'vue-property-decorator'

// 团队选择组件
@Component({ name: 'ApiParamTypeSelector', components: {}, mixins: [] })
export default class ApiParamTypeSelector extends Vue {
  // 最新的vue版本一定要指定value 与 input
  @Model('input')
  readonly value?: number|string

  curValue = 0

  @Prop({
    type: String,
    default: '输入标题或者名称'
  })
  placeholder!: string

  @Prop({
    type: Number,
    default: undefined
  })
  curVersionId?: number

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  loading = false

  datas: Array<any> = []

  created () {
    this.loadDataFromServer()
  }

  onChange (newValue: number) {
    this.$emit('input', newValue)
    this.curValue = newValue
  }

  onSelect (newValue: any) {
    this.$emit('input', newValue.value)
    this.curValue = newValue.value
  }

  initData () {
    this.$emit('input', undefined)
    this.curValue = -1
  }

  // 加载下拉框选项
  loadDataFromServer () {
    this.datas = [{
      id: 0,
      value: 0,
      label: '@PathVariable'
    }, {
      id: 1,
      value: 1,
      label: '@RequestHeader'
    }, {
      id: 2,
      value: 2,
      label: '@RequestParam'
    }, {
      id: 3,
      value: 3,
      label: 'Request'
    }, {
      id: 4,
      value: 4,
      label: 'Response'
    }, {
      id: 5,
      value: 5,
      label: 'Vo'
    }, {
      id: 6,
      value: 6,
      label: 'QueryBean'
    }, {
      id: 7,
      value: 7,
      label: 'Other'
    }
    ]
  }
}
