import { ApiParamConfig } from '@/api/project/apimanager/api-param-config/api-param-config'
import { showModal } from '@/libs/modal-helper'
import ApiParamCreatorModal from './api-param-creator-modal.vue'
import AutoLimitEditModal from './auto-limit-edit-modal.vue'

export default class Modals {
  static showApiParamConfigCreatorModal (apiConfigId: number, onOk?: (data: ApiParamConfig) => Promise<void>) {
    showModal<any>(ApiParamCreatorModal, {
      props: {
        ifFromEntity: false
      }
    }, true, onOk, undefined)
  }

  static showApiParamConfigUpdaterModal (config: ApiParamConfig, onOk?: (data: ApiParamConfig) => Promise<void>) {
    showModal<any>(ApiParamCreatorModal, {
      props: {
        title: '修改Api参数',
        dataProps: config
      }
    }, true, onOk, undefined)
  }

  static showAutoLimitEditModal (limits: string, onOk?: (data: string) => Promise<void>) {
    showModal(AutoLimitEditModal, {
      props: {
        limits
      }
    }, true, onOk, undefined)
  }
}
